<template>
  <div class="login-pc">
    <pc-header></pc-header>
    <div class="main">
      <div class="login-box">
        <div class="title">用户登录</div>
        <div class="content">
          <div class="login-tab">
            <span :class="[active ? 'active' : '']" @click="active = true">验证码登录</span>
            <span :class="[active ? '' : 'active']" @click="active = false">账号登录</span>
          </div>
          <div class="form">
            <div v-if="active" class="form-code">
              <yl-input v-model="formData.key" placeholder="请输入手机号"><img src="../../assets/phone.png" alt="" /></yl-input>
              <p class="forget"></p>
              <yl-input v-model="formData.verificationCode" :phone="formData.key" :code="true" placeholder="请输入验证码"><img src="../../assets/code.png" alt="" /></yl-input>
              <p class="tip">*仅限中国大陆手机，其他地区请使用账号登录</p>
            </div>
            <div v-else class="form-account">
              <yl-input v-model="passwordFormData.key" placeholder="账号为您的手机号或邮箱"><img src="../../assets/name.png" alt="" /></yl-input>
              <p class="tip"></p>
              <yl-input v-model="passwordFormData.password" type="password" placeholder="请输入密码"><img src="../../assets/password.png" alt="" /></yl-input>
              <p class="forget">
                <span @click="toRetrieve()">忘记密码？</span>
              </p>
            </div>
          </div>
          <div class="btn-wrap">
            <button class="login" @click="login()">立即登录</button>
            <button class="register" @click="toRegister()">
              还没有账号？立即注册
            </button>
          </div>
        </div>
      </div>
    </div>
    <pc-footer></pc-footer>
  </div>
</template>

<script>
import PcHeader from '../../components/pc-header.vue';
import PcFooter from '../../components/pc-footer.vue';
import YlInput from '../../components/yl-input.vue';
import { accountLogin, codeLogin } from '../../api/user';
import { setToken } from '../../utils/auth';
export default {
  components: {
    PcHeader,
    PcFooter,
    YlInput
  },
  data() {
    return {
      active: true,
      formData: {
        key: '',
        verificationCode: ''
      },
      passwordFormData: {
        key: '',
        password: '',
        wxopenId: ''
      }
    };
  },
  created() {
    // console.log(this.$route.query.st);
  },
  methods: {
    login() {
      if (this.active) {
        // 验证码登录
        codeLogin(this.formData).then(res => {
          if (res.code == 0) {
            setToken(res.token);
            this.$ylmessage.success('登录成功');
            setTimeout(() => {
              if (this.$route.query.w) {
                this.$router.replace({
                  path: '/watch/' + this.$route.query.w,
                  query: { st: this.$route.query.st }
                });
              } else if (this.$route.query.redirect) {
                location.replace(this.$route.query.redirect);
              } else {
                this.$router.replace({ path: '/' });
              }
            }, 1500);
          } else {
            this.$ylmessage.error(res.msg);
          }
        });
      } else {
        // 账号登录
        accountLogin(this.passwordFormData).then(res => {
          if (res.code == 0) {
            setToken(res.token);
            this.$ylmessage.success('登录成功');
            setTimeout(() => {
              if (this.$route.query.w) {
                this.$router.replace({
                  path: '/watch/' + this.$route.query.w,
                  query: { st: this.$route.query.st }
                });
              } else if (this.$route.query.redirect) {
                location.replace(this.$route.query.redirect);
              } else {
                this.$router.replace({ path: '/' });
              }
            }, 1500);
          } else {
            this.$ylmessage.error(res.msg);
          }
        });
      }
    },
    toRegister() {
      this.$router.push({
        path: '/clause',
        query: {
          r: this.$route.query.r,
          c: this.$route.query.c,
          w: this.$route.query.w
        }
      });
    },
    toRetrieve() {
      this.$router.push({ path: '/retrieve' });
    }
  }
};
</script>

<style lang="scss" scoped>
.login-pc {
  height: 100%;
  background: url('../../assets/pcbgsmall.png') 0 0 no-repeat;
  background-size: 100% auto;
  .main {
    margin-top: 30px;
    min-height: 550px;
    .login-box {
      width: 1200px;
      height: 470px;
      background-color: #fff;
      margin: 0 auto;
      .title {
        font-size: 20px;
        font-weight: 500;
        padding-left: 30px;
        height: 57px;
        line-height: 57px;
        border-bottom: 1px solid #ececec;
      }
      .content {
        width: 610px;
        margin: 36px auto 0;
        .login-tab {
          height: 60px;
          background-color: #ececec;
          span {
            cursor: pointer;
            display: inline-block;
            width: 50%;
            line-height: 60px;
            text-align: center;
            font-size: 16px;
            color: #999;
          }
          .active {
            color: #fff;
            background-color: #1872bb;
          }
        }
        .form {
          padding: 20px 0 10px;
          .tip {
            padding-left: 50px;
            height: 16px;
            font-size: 12px;
            margin: 0 0 15px;
            color: #666;
          }
          .forget {
            cursor: pointer;
            height: 12px;
            font-size: 12px;
            color: #666;
            text-align: right;
          }
        }
        .btn-wrap {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          button {
            cursor: pointer;
            width: 280px;
            height: 40px;
            font-size: 14px;
            border: none;
            border-radius: 0.2rem;
            background: rgba($color: #000000, $alpha: 0);
            font-weight: bold;
          }
          .login {
            margin-bottom: 20px;
            color: #fff;
            background: #1872bb;
          }
          .register {
            color: #1872bb;
            // background: #fff;
            border: 1px solid #1872bb;
          }
        }
      }
    }
  }
}
</style>
