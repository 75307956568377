<template>
  <div class="login-mob">
    <div class="top">
      <div class="logo">
        <img src="../../assets/logo.png" alt="" />
      </div>
      <div class="title">用户登录</div>
    </div>
    <div class="main">
      <div class="form-wrap">
        <div class="tab-bar">
          <span :class="[active ? 'active' : '']" @click="active = true">验证码登录</span>
          <span :class="[active ? '' : 'active']" @click="active = false">账号登录</span>
        </div>
        <div class="form">
          <div v-if="active">
            <yl-input v-model="formData.key" placeholder="请输入手机号"><img src="../../assets/phone.png" alt="" /></yl-input>
            <p class="forget"></p>
            <yl-input v-model="formData.verificationCode" :phone="formData.key" :code="true" placeholder="请输入验证码"><img src="../../assets/code.png" alt="" /></yl-input>
            <p class="tip">*仅限中国大陆手机，其他地区请使用账号登录</p>
          </div>
          <div v-else>
            <yl-input v-model="passwordFormData.key" placeholder="账号为您的手机号或邮箱"><img src="../../assets/name.png" alt="" /></yl-input>
            <p class="tip"></p>
            <yl-input v-model="passwordFormData.password" type="password" placeholder="请输入密码"><img src="../../assets/password.png" alt="" /></yl-input>
            <p class="forget">
              <span @click="toRetrieve()">忘记密码？</span>
            </p>
          </div>
        </div>
      </div>
      <div class="btn-wrap">
        <button class="login" @click="login()">立即登录</button>
        <button class="register" @click="toRegister()">
          还没有账号？立即注册
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import YlInput from '../../components/yl-input.vue';
import { accountLogin, codeLogin, wechatConfig } from '../../api/user';
import { setToken } from '../../utils/auth';
import wechat from '../../utils/wxShare';
export default {
  components: {
    YlInput
  },
  data() {
    return {
      active: true,
      formData: {
        key: '',
        verificationCode: '',
        wxHeadImg: '',
        wxNickName: '',
        wxopenId: ''
      },
      passwordFormData: {
        key: '',
        password: '',
        wxHeadImg: '',
        wxNickName: '',
        wxopenId: ''
      }
    };
  },
  beforeCreate() {
    this.$wxAuth();
  },
  created() {
    console.log(this.$route);
    let wxUserInfo = JSON.parse(sessionStorage.getItem('wxUser'));
    if (wxUserInfo) {
      this.formData.wxHeadImg = wxUserInfo.wxHeadImg;
      this.formData.wxNickName = wxUserInfo.wxNickName;
      this.formData.wxopenId = wxUserInfo.openid;
      this.passwordFormData.wxHeadImg = wxUserInfo.wxHeadImg;
      this.passwordFormData.wxNickName = wxUserInfo.wxNickName;
      this.passwordFormData.wxopenId = wxUserInfo.openid;
      this.getWechatConfig();
    }
  },
  methods: {
    getWechatConfig() {
      wechatConfig({
        url: location.href
      }).then(res => {
        if (res.code == 0) {
          this.wxConfigData = res.data;
          wechat.share(this.wxConfigData, '因美纳在线讲堂', '因美纳在线讲堂', this.$store.state.url, location.href);
        }
      });
    },
    login() {
      if (this.active) {
        // 验证码登录
        codeLogin(this.formData).then(res => {
          if (res.code == 0) {
            setToken(res.token);
            this.$ylmessage.success('登录成功');
            setTimeout(() => {
              if (this.$route.query.w) {
                this.$router.replace({
                  path: '/watch/' + this.$route.query.w,
                  query: { st: this.$route.query.st }
                });
              } else if (this.$route.query.redirect) {
                location.replace(this.$route.query.redirect);
              } else {
                this.$router.replace({ path: '/' });
              }
            }, 1500);
          } else {
            this.$ylmessage.error(res.msg);
          }
        });
      } else {
        // 账号登录
        accountLogin(this.passwordFormData).then(res => {
          if (res.code == 0) {
            setToken(res.token);
            this.$ylmessage.success('登录成功');
            setTimeout(() => {
              if (this.$route.query.w) {
                this.$router.replace({
                  path: '/watch/' + this.$route.query.w,
                  query: { st: this.$route.query.st }
                });
              } else if (this.$route.query.redirect) {
                location.replace(this.$route.query.redirect);
              } else {
                this.$router.replace({ path: '/' });
              }
            }, 1500);
          } else {
            this.$ylmessage.error(res.msg);
          }
        });
      }
    },
    toRegister() {
      this.$router.push({
        path: '/clause',
        query: {
          r: this.$route.query.r,
          c: this.$route.query.c,
          w: this.$route.query.w
        }
      });
    },
    toRetrieve() {
      this.$router.push({ path: '/retrieve' });
    }
  }
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}
.login-mob {
  height: 100%;
  background: url('../../assets/h5bg.png') 0 0 no-repeat;
  background-size: 100% auto;
  .top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 1.35rem;
    .logo {
      width: 1.85rem;
      img {
        width: 100%;
      }
    }
    .title {
      margin-top: 0.4rem;
      font-size: 0.16rem;
      color: #fff;
      font-weight: bold;
      letter-spacing: 2px;
    }
  }
  .main {
    padding: 0 0.25rem;
    .form-wrap {
      background: #fff;
      //   height: 2.5rem;
      border-radius: 8px;
      overflow: hidden;
      .tab-bar {
        height: 45px;
        background: #eaeaea;
        span {
          display: inline-block;
          width: 50%;
          text-align: center;
          line-height: 45px;
          font-size: 14px;
          color: #999;
        }
        span.active {
          color: #000;
          font-weight: bold;
          background: #fff;
        }
      }
      .form {
        padding: 40px 20px;
        .tip {
          height: 16px;
          font-size: 12px;
          margin: 0 0 15px;
          color: #666;
        }
        .forget {
          height: 12px;
          font-size: 12px;
          color: #666;
          text-align: right;
        }
      }
    }
    .btn-wrap {
      // display: flex;
      // align-items: center;
      padding: 0.3rem 0;
      button {
        display: block;
        width: 100%;
        margin-bottom: 0.15rem;
        height: 0.4rem;
        border: none;
        border-radius: 0.2rem;
        background: rgba($color: #000000, $alpha: 0);
        font-weight: bold;
        font-size: 0.14rem;
      }
      .login {
        color: #fff;
        background: #1872bb;
      }
      .register {
        color: #1872bb;
        // background: #fff;
        border: 1px solid #1872bb;
      }
    }
  }
}
</style>
