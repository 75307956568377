<template>
  <div class="page-view">
    <login-mob v-if="isMobile"></login-mob>
    <login-pc v-else></login-pc>
  </div>
</template>

<script>
import LoginMob from "./login-mob.vue";
import LoginPc from "./login-pc.vue";
export default {
  components: {
    LoginMob,
    LoginPc,
  },
  metaInfo: {
    title: "登录",
  },
  data() {
    return {
      isMobile: this.$isMobile(),
    };
  },
};
</script>
